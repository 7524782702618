import React, { useState } from 'react';

import { ImageGallery, RichText } from 'components/UI';
import content from 'data/case-studies.json';
import { Colors, MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext } from 'hooks';

import {
	Container,
	Content,
	DetailsContainer,
	DetailsHeader,
	DetailsItem,
	FloatingContainer,
	ImageContainer,
	Image,
	OverviewCategory,
	OverviewContainer,
	OverviewDescription,
	OverviewTitle,
	StudyContainer,
	FooterDescription,
	ViewGallery
} from './Studies.style';

function getDetailsBackgroundColor(index: number) {
	switch (index) {
		case 0: {
			return Colors.indigo;
		}

		case 2: {
			return Colors.gray[1];
		}

		default:
			return Colors.white;
	}
}

export function Studies() {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);
	const { images } = useQueryContext();

	const [galleryOpen, setGalleryOpen] = useState(false);

	function handleGalleryClose() {
		setGalleryOpen(false);
	}

	function handleGalleryOpen() {
		setGalleryOpen(true);
	}

	return (
		<Container>
			{images.cases.studies.map((imgs, i) => (
				<ImageContainer key={`case-study-${i}`}>
					{isPhone && imgs.cover.mobile && (
						<Image fluid={imgs.cover.mobile.fluid} alt={content.cases[i].alt} />
					)}
					{isTablet && imgs.cover.tablet && (
						<Image fluid={imgs.cover.tablet.fluid} alt={content.cases[i].alt} />
					)}
					{isDesktop && imgs.cover.desktop && (
						<Image fluid={imgs.cover.desktop.fluid} alt={content.cases[i].alt} />
					)}
					<ImageGallery
						images={imgs.location}
						open={galleryOpen}
						onClose={handleGalleryClose}
					/>
					<FloatingContainer>
						<Content>
							<StudyContainer>
								{isDesktop && (
									<DetailsContainer>
										{content.cases[i].details.map((section, index) => (
											<div key={`case-study-${i}-section-${index}`}>
												<DetailsHeader>{section.header}</DetailsHeader>
												{section.items.map((item, j) => (
													<RichText
														key={`case-study-${i}-section-${index}-item-${j}`}
													>
														<DetailsItem>{item}</DetailsItem>
													</RichText>
												))}
											</div>
										))}
										{(content.cases[i].footer?.header ||
											content.cases[i].footer?.description) && (
											<>
												<DetailsHeader>
													{content.cases[i].footer?.header}
												</DetailsHeader>
												<RichText>
													<FooterDescription>
														{content.cases[i].footer?.description}
													</FooterDescription>
												</RichText>
											</>
										)}
										{/* <ViewGallery onClick={handleGalleryOpen}>
											{content.cases[i].gallery.label}
										</ViewGallery> */}
									</DetailsContainer>
								)}
								<OverviewContainer>
									<OverviewCategory>{content.cases[i].category}</OverviewCategory>
									<OverviewTitle>{content.cases[i].title}</OverviewTitle>
									<RichText>
										<OverviewDescription>
											{content.cases[i].description}
										</OverviewDescription>
									</RichText>
								</OverviewContainer>
							</StudyContainer>
						</Content>
					</FloatingContainer>
					{!isDesktop && (
						<DetailsContainer backgroundColor={getDetailsBackgroundColor(i)}>
							<Content>
								{content.cases[i].details.map((section, index) => (
									<div key={`case-study-${i}-section-${index}`}>
										<DetailsHeader>{section.header}</DetailsHeader>
										{section.items.map((item, j) => (
											<RichText
												key={`case-study-${i}-section-${index}-item-${j}`}
											>
												<DetailsItem>{item}</DetailsItem>
											</RichText>
										))}
									</div>
								))}
								{(content.cases[i].footer?.header ||
									content.cases[i].footer?.description) && (
									<>
										<DetailsHeader>
											{content.cases[i].footer?.header}
										</DetailsHeader>
										<RichText>
											<FooterDescription>
												{content.cases[i].footer?.description}
											</FooterDescription>
										</RichText>
									</>
								)}
								{/* <ViewGallery onClick={handleGalleryOpen}>
									{content.cases[i].gallery.label}
								</ViewGallery> */}
							</Content>
						</DetailsContainer>
					)}
				</ImageContainer>
			))}
		</Container>
	);
}
