import React from 'react';

import { Carousel, RichText } from 'components/UI';
import content from 'data/landing.json';
import { MediaQueries } from 'environment';
import { shouldInvertColor } from 'helpers';
import { useMediaQuery, useQueryContext } from 'hooks';

import {
	Caption,
	Category,
	Container,
	Content,
	Description,
	Image,
	Slide,
	Link,
	Title
} from './LandingUseCases.style';

export function LandingUseCases() {
	const { images } = useQueryContext();
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	return (
		<Container>
			<Carousel autoPlay={5000}>
				{images.cases.landing.map((c, i) => {
					const inverted = shouldInvertColor([
						{
							viewport: content.cases[i].invertColor?.desktop,
							value: isDesktop
						}
					]);

					return (
						<Carousel.Item key={`landing-case-${i}`}>
							<Slide>
								{isPhone && c.mobile && (
									<Image fluid={c.mobile.fluid} alt={content.cases[i].alt} />
								)}
								{isTablet && c.tablet && (
									<Image fluid={c.tablet.fluid} alt={content.cases[i].alt} />
								)}
								{isDesktop && c.desktop && (
									<Image fluid={c.desktop.fluid} alt={content.cases[i].alt} />
								)}
								<Content>
									<Caption>
										<Category inverted={inverted}>
											{content.cases[i].category}
										</Category>
										<Title inverted={inverted}>{content.cases[i].title}</Title>
										<RichText>
											<Description inverted={inverted}>
												{content.cases[i].description}
											</Description>
										</RichText>
										<Link to="/case-studies/">{content.cases[i].cta}</Link>
									</Caption>
									<Carousel.Dots />
								</Content>
							</Slide>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</Container>
	);
}
