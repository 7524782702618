import styled from 'styled-components';
import { DESKTOP_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;
	background-color: ${Colors.indigo};

	@media ${MediaQueries.phone} {
		padding: 6rem 0;
	}
`;

export const Title = styled.h2`
	color: ${Colors.white};
	margin-bottom: 8rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 4rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;
