import Image from 'gatsby-image';
import styled from 'styled-components/macro';
import { MediaQueries } from 'environment';

interface SectionProps {
	isPageHeader?: boolean;
}

export const Section = styled.section<SectionProps>`
	width: 100%;

	@media ${MediaQueries.desktop} {
		display: flex;
		justify-content: flex-end;
		margin-top: ${({ isPageHeader = false }) => isPageHeader && '4rem'};
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 6rem;
	padding: 0 2rem;

	p {
		margin-top: 2rem;
		text-align: center;
	}

	a {
		margin-top: 4rem;
	}

	a + p {
		margin-bottom: 2rem;
		font-weight: 600;
	}

	@media ${MediaQueries.phone} {
		h2 {
			font-size: 2.4rem;
			line-height: 2.9rem;
		}
	}

	@media ${MediaQueries.tablet} {
		margin-top: 16rem;
		padding: 0 7.4rem;

		a {
			max-width: 30rem;
			margin-top: 8rem;
		}
	}

	@media ${MediaQueries.desktop} {
		align-items: flex-start;
		margin-top: 16rem;
		margin-bottom: 6rem;
		margin-right: 15rem;
		max-width: 58rem;

		p {
			text-align: start;
		}

		a {
			max-width: 38rem;
			margin-top: 7rem;
		}

		a + p {
			margin-bottom: 6rem;
		}
	}
`;

export const Footer = styled.div`
	margin-top: 2rem;
	position: relative;

	@media ${MediaQueries.tablet} {
		margin-top: 5rem;
	}

	@media ${MediaQueries.desktop} {
		flex: 1;
		margin: 0;
		max-width: 50%;
	}
`;

export const FooterImage = styled(Image)`
	width: 100%;

	@media ${MediaQueries.desktop} {
		flex: 1;
		height: 100%;

		img {
			object-position: left center !important;
		}
	}
`;
