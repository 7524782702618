import Img from 'gatsby-image';
import styled from 'styled-components';

import { TABLET_GRID_SIZE, DESKTOP_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
	overflow: hidden;
`;

export const Image = styled(Img)`
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: none;
	user-select: none;
`;

export const BackgroundImage = styled.div`
	display: flex;
	width: 100vw;
	transition: opacity 0.2s;
`;

export const Spotlight = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 1;
	transition: opacity 0.3s;
`;

export const Details = styled.div`
	position: absolute;
	top: 12rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 2rem;
	top: 2rem;

	@media ${MediaQueries.phone} and (min-width: ${Sizes.xs}) {
		top: 4rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		align-items: center;
		padding: 0;
		top: 12rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
		justify-content: flex-start;
		padding: 0;
		top: 12rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
		top: 8rem;
	}
`;

export const Text = styled.h1`
	max-width: 58rem;
	text-align: center;
	font-size: 3.6rem;
	line-height: 5rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-weight: 600;
		margin-bottom: 1rem;
	}

	@media ${MediaQueries.tablet} {
		font-size: 7.2rem;
		line-height: 8.6rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		font-size: 7.2rem;
		line-height: 8.6rem;
		text-align: left;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		max-width: 40rem;
		font-size: 4.8rem;
		line-height: 5.7rem;
		margin-bottom: 2rem;
	}
`;
