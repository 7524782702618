import styled from 'styled-components';
import { DESKTOP_GRID_SIZE, DESKTOP_GRID_COLUMN, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;
	background-color: ${Colors.gray[1]};

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}
`;

export const Title = styled.h1`
	margin-bottom: 8rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 4rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Conclusion = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;
	margin-top: 6rem;
	margin-bottom: 8rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-top: 2rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	@media ${MediaQueries.desktop} {
		margin-left: ${2 * DESKTOP_GRID_COLUMN}rem;
		margin-right: ${2 * DESKTOP_GRID_COLUMN}rem;
	}
`;

export const Emphasised = styled.span`
	font-family: 'Open Sans';
	font-size: 3.6rem;
	font-weight: 600;
	color: ${Colors.green};

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 6rem;
	}
`;
