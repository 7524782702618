import React from 'react';

import { IAQSchools } from 'components/CaseStudies';
import {
	LandingBlogPosts,
	LandingBusiness,
	LandingHero,
	LandingLiveStats,
	LandingSolution,
	LandingUseCases
} from 'components/Landing';
import { BannerCta, SEO } from 'components/UI';
import { cta } from 'data/landing.json';
import seo from 'data/seo.json';

export default function Landing() {
	return (
		<>
			<SEO
				title={seo.landingTitle}
				description={seo.landingDescription}
				ogTitle={seo.landingOgTitle}
				ogDescription={seo.landingOgDescription}
				ogImage={seo.landingOgImage}
			/>
			<LandingHero />
			<LandingSolution />
			<LandingLiveStats />
			<LandingBusiness />
			<LandingUseCases />
			<BannerCta {...cta} />
			<IAQSchools />
			<LandingBlogPosts />
		</>
	);
}
