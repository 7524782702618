import Img from 'gatsby-image';
import styled from 'styled-components';

import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const ImageContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const Image = styled(Img)`
	width: 100%;
	user-select: none;
	pointer-events: none;
`;

export const FloatingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const StudyContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-top: 6rem;

	${Container} > ${ImageContainer}:nth-child(even) & {
		flex-direction: row-reverse;
	}

	@media ${MediaQueries.tablet} {
		padding-top: 12rem;
	}

	@media ${MediaQueries.desktop} {
		padding-top: 4rem;
	}
`;

interface Props {
	backgroundColor?: string;
}

export const DetailsContainer = styled.div<Props>`
	background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
	display: flex;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding-bottom: 6rem;
	}

	@media ${MediaQueries.tablet} {
		justify-content: center;
		padding-top: 4rem;
		padding-bottom: 12rem;
	}

	@media ${MediaQueries.desktop} {
		flex-direction: column;
		width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
	}

	@media ${MediaQueries.minWidth.xl} {
		width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 4}rem;
	}
`;

export const DetailsHeader = styled.p`
	font-weight: 600;
	margin: 2rem 0;
	text-transform: uppercase;

	${Container} > ${ImageContainer}:first-child & {
		color: ${Colors.gray[1]};
	}

	@media ${MediaQueries.phone} {
		margin-top: 4rem;
	}

	@media ${MediaQueries.tablet} {
		margin-top: 4rem;
	}

	@media ${MediaQueries.minWidth.lg} {
		margin-bottom: 1rem;
	}

	@media ${MediaQueries.minWidth.hd} {
		margin-top: 4rem;
	}
`;

export const DetailsItem = styled.li`
	${Container} > ${ImageContainer}:first-child & {
		color: ${Colors.gray[1]};
	}

	@media ${MediaQueries.minWidth.lg} {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	@media ${MediaQueries.minWidth.xl} {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}

	@media ${MediaQueries.minWidth.fhd} {
		font-size: 1.8rem;
		line-height: 2.7rem;
	}
`;

export const FooterDescription = styled.p`
	@media ${MediaQueries.minWidth.lg} {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	@media ${MediaQueries.minWidth.xl} {
		font-size: 1.4rem;
		line-height: 2.2rem;
	}

	@media ${MediaQueries.minWidth.fhd} {
		font-size: 1.8rem;
		line-height: 2.7rem;
	}
`;

export const ViewGallery = styled.p`
	align-self: flex-start;
	border-bottom: 0.2rem transparent solid;
	color: ${Colors.green};
	cursor: pointer;
	font-weight: bold;
	margin-top: 4rem;
	transition: all 0.2s;
	width: fit-content;

	&:hover {
		color: ${Colors.green};
		border-color: ${Colors.green};
	}

	@media ${MediaQueries.desktop} {
		margin-top: 2rem;
	}

	@media ${MediaQueries.minWidth.hd} {
		margin-top: 4rem;
	}
`;

export const OverviewContainer = styled.div`
	@media ${MediaQueries.desktop} {
		width: ${DESKTOP_GRID_COLUMN * 4 + DESKTOP_GRID_SPACING * 3}rem;
	}

	@media ${MediaQueries.minWidth.hd} {
		margin-top: 4rem;
	}

	@media ${MediaQueries.minWidth.fhd} {
		margin-top: 8rem;
	}
`;

export const OverviewCategory = styled.span`
	color: ${Colors.indigo};
`;

export const OverviewTitle = styled.h2`
	margin: 2rem 0;
	text-transform: uppercase;
	margin-right: 1rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		font-size: 3.6rem;
		line-height: 4.3rem;
		margin: 1rem 0;
		margin-right: 1rem;
	}
`;

export const OverviewDescription = styled.p`
	font-size: 1.4rem;
	line-height: 2.2rem;
`;
