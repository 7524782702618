import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'components/UI';
import landing from 'data/landing.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext, useWindowScrollPosition, useWindowSize } from 'hooks';

import { BackgroundImage, Container, Image, Text, Details, Spotlight } from './LandingHero.style';

interface MousePosition {
	x: number;
	y: number;
}

export function LandingHero() {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);
	const { width: windowWidth } = useWindowSize();

	const {
		images: {
			landing: { hero }
		}
	} = useQueryContext();
	const [position, setPosition] = useState<MousePosition>({ x: 500, y: 500 });
	const [opacity, setOpacity] = useState(1);

	const heroRef = useRef<HTMLDivElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useWindowScrollPosition(({ current }) => {
		setOpacity(Math.max(0, 1 - current.y / 200));
	}, []);

	useEffect(() => {
		function onMouseMove(e: MouseEvent) {
			if (
				window.scrollY === 0 &&
				heroRef.current &&
				buttonRef.current &&
				heroRef.current.contains(e.target as Node) &&
				!buttonRef.current.contains(e.target as Node)
			) {
				setPosition({ x: e.pageX, y: e.pageY });
			}
		}

		if (isDesktop) {
			document.addEventListener('mousemove', onMouseMove);
		}

		return () => {
			document.removeEventListener('mousemove', onMouseMove);
		};
	}, [isDesktop]);

	return (
		<Container ref={heroRef}>
			{isPhone && hero.mobile && <Image fluid={hero.mobile.fluid} alt={landing.hero.alt} />}
			{isTablet && hero.tablet && <Image fluid={hero.tablet.fluid} alt={landing.hero.alt} />}
			{isDesktop && hero.desktop && (
				<BackgroundImage
					style={{
						background: `url(${hero.desktop.fluid.src})`,
						backgroundSize: 'contain',
						backgroundRepeat: 'no-repeat',
						height: (windowWidth - 14) / 2.46153846154
					}}
				>
					<Spotlight
						style={{
							background: `radial-gradient(circle at ${position.x}px ${
								position.y - 120
							}px, transparent, rgba(255, 255, 255, 0.8) 30%)`,
							opacity
						}}
					/>
				</BackgroundImage>
			)}
			<Details>
				<Text>{landing.hero.text}</Text>
				<Button
					name={analytics.landingHeroDiscoverSensix}
					ref={buttonRef}
					to="/solution/"
					width={isTablet ? 30 : isDesktop ? 28 : '100%'}
				>
					{landing.hero.cta}
				</Button>
			</Details>
		</Container>
	);
}
