import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 12rem;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
		padding-top: 6rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const Title = styled.h2`
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}
`;

export const Description = styled.p`
	max-width: 80rem;
	text-align: center;
	margin-bottom: 8rem;
`;
