import Img from 'gatsby-image';
import styled from 'styled-components';

import { TextLink } from 'components/UI/TextLink';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	width: 100%;
	height: 100%;
`;

export const Image = styled(Img)`
	width: 100%;
`;

export const Slide = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;

	@media ${MediaQueries.phone} {
		min-height: 72rem;
	}

	@media ${MediaQueries.tablet} {
		min-height: 92rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	height: 100%;
	width: 100%;
	padding: 6rem 0;

	@media ${MediaQueries.phone} {
		padding: 4rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		padding-top: 8rem;
		padding-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
		align-items: flex-start;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
	}

	@media (min-width: ${Sizes.fhd}) {
		padding: 12rem 0;
	}
`;

export const Caption = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media ${MediaQueries.desktop} {
		align-items: flex-start;
	}
`;

interface TextProps {
	inverted?: boolean;
}

export const Category = styled.span<TextProps>`
	color: ${({ inverted }) => (inverted ? Colors.gray[1] : Colors.indigo)};
`;

export const Title = styled.h2<TextProps>`
	color: ${({ inverted }) => inverted && Colors.gray[1]};
	margin: 1rem 0;
	text-transform: uppercase;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}

	@media ${MediaQueries.tablet} {
		margin: 2rem 0;
	}

	@media ${MediaQueries.desktop} {
		margin: 4rem 0;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		font-size: 3.6rem;
		line-height: 4.3rem;
		margin: 1rem 0;
	}
`;

export const Description = styled.p<TextProps>`
	color: ${({ inverted }) => inverted && Colors.gray[1]};
	margin-bottom: 1rem;
	max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	text-align: left;

	@media ${MediaQueries.phone} {
		text-align: center;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 2rem;
	}

	@media ${MediaQueries.desktop} {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		font-size: 1.6rem;
		line-height: 1.9rem;
		max-width: 50%;
	}
`;

export const Link = styled(TextLink)`
	margin-bottom: 1rem;
`;
