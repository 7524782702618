import React from 'react';

import { Button } from 'components/UI';
import content from 'data/case-studies.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext } from 'hooks';

import { Banner, Container, Content, Title } from './StudiesCta.style';

export function StudiesCta() {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const { images } = useQueryContext();

	return (
		<Container>
			{images.banners.landing && <Banner image={images.banners.landing} />}
			<Content>
				<Title>{content.cta.title}</Title>
				<Button
					name={analytics.caseStudiesFindOutMore}
					to="/solution/"
					width={!isPhone ? 28 : '100%'}
				>
					{content.cta.label}
				</Button>
			</Content>
		</Container>
	);
}
