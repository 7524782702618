import React from 'react';

import { Container, Count, Description, Separator } from './LiveStatsItem.style';

interface Props {
	icon: {
		src: string;
		alt: string;
	};
	count: string;
	description: string;
}

export function LiveStatsItem({ icon, count, description }: Props) {
	return (
		<Container>
			<img src={`/images/${icon.src}`} alt={icon.alt} />
			<Count>{count}</Count>
			<Separator />
			<Description>{description}</Description>
		</Container>
	);
}
