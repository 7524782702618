import styled from 'styled-components';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	&:not(:last-child) {
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		margin-bottom: 0;
	}
`;

export const Count = styled.h2`
	color: ${Colors.white};
	margin: 2rem 0;
`;

export const Separator = styled.div`
	background-color: ${Colors.green};
	width: ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING}rem;
	height: 0.5rem;
	border-radius: 0.25rem;
`;

export const Description = styled.p`
	color: ${Colors.gray[1]};
	margin-top: 2rem;
`;
