import React from 'react';

import content from 'data/case-studies.json';

import { Container, Content, Description, Title } from './StudiesHeader.style';

export function StudiesHeader() {
	return (
		<Container>
			<Content>
				<Title>{content.title}</Title>
				<Description>{content.description}</Description>
			</Content>
		</Container>
	);
}
