import React from 'react';

import data from 'data/landing.json';
import { BlogPostPreviewList } from 'components/Blog';
import { useQueryContext } from 'hooks';

import { Container, Content } from './LandingBlogPosts.style';

export function LandingBlogPosts() {
	const { posts } = useQueryContext();

	return (
		<Container>
			<Content>
				<BlogPostPreviewList title={data.posts.title} posts={posts.slice(0, 3)} />
			</Content>
		</Container>
	);
}
