import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 0;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;
