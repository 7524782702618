import React from 'react';

import landing from 'data/landing.json';

import { LiveStatsItem } from './LiveStatsItem';
import { Container, Content, Title } from './LandingLiveStats.style';

export function LandingLiveStats() {
	return (
		<Container>
			<Title>{landing.stats.title}</Title>
			<Content>
				{landing.stats.items.map((stat, index) => (
					<LiveStatsItem key={`live-stat-${index}`} {...stat} />
				))}
			</Content>
		</Container>
	);
}
