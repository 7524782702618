import React from 'react';

import { SolutionFeature } from 'components/UI';
import landing from 'data/landing.json';
import { useQueryContext } from 'hooks';

import { Container, Title, Description } from './LandingSolution.style';

export function LandingSolution() {
	const { images } = useQueryContext();

	return (
		<Container>
			<Title>{landing.solution.title}</Title>
			<Description>{landing.solution.description}</Description>
			{landing.solution.features.map((f, i) => (
				<SolutionFeature
					key={`${f.title}-feature`}
					{...f}
					category={f.category}
					image={images.landing.solution[i]}
					reverse={i % 2 !== 0}
				/>
			))}
		</Container>
	);
}
