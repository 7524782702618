import React from 'react';

import { Button, BusinessItem, RichText } from 'components/UI';
import { business } from 'data/landing.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import { Conclusion, Container, Content, Title } from './LandingBusiness.style';

export function LandingBusiness() {
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	return (
		<Container>
			<Title>{business.title}</Title>
			<Content>
				{business.items.map((item, i) => (
					<BusinessItem key={`business-item-${i}`} {...item} />
				))}
				<RichText>
					<Conclusion>{business.conclusion}</Conclusion>
				</RichText>
				<Button
					name={analytics.landingBusinessRequestQuote}
					to="/request-quote/"
					state={{ plan: 'Starter' }}
					width={isTablet ? 30 : isDesktop ? 38 : '100%'}
				>
					{business.cta}
				</Button>
			</Content>
		</Container>
	);
}
