import React from 'react';

import { Button } from 'components/UI';
import content from 'data/case-studies.json';
import { MediaQueries } from 'environment';
import { useQueryContext, useMediaQuery } from 'hooks';

import { Content, Footer, FooterImage, Section } from './IAQSchools.style';

interface Props {
	path?: string;
}

export function IAQSchools({ path }: Props) {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);
	const { images } = useQueryContext();

	return (
		<Section isPageHeader={path?.includes('case-studies')}>
			<Content>
				<h2>{content.iaq.title}</h2>
				<p>{content.iaq.description}</p>
				<Button href={content.iaq.cta.href} width="100%">
					{content.iaq.cta.title}
				</Button>
				<p>{content.iaq.cta.description}</p>
				<p>{content.iaq.conclusion}</p>
			</Content>
			<Footer>
				{isPhone && images.cases.iaqInSchools.mobile && (
					<FooterImage fluid={images.cases.iaqInSchools.mobile.fluid} />
				)}
				{isTablet && images.cases.iaqInSchools.tablet && (
					<FooterImage fluid={images.cases.iaqInSchools.tablet.fluid} />
				)}
				{isDesktop && images.cases.iaqInSchools.desktop && (
					<FooterImage fluid={images.cases.iaqInSchools.desktop.fluid} />
				)}
			</Footer>
		</Section>
	);
}
